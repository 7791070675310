<!--<template>
   <v-hover v-slot="{ hover }">
     <v-card
       outlined
       :elevation="hover ? 4 : 2"
       @click="
         $emit('click');
         openDetails();
       "
     >
       <div class="d-flex flex-no-wrap justify-space-between">
         <div>
           <v-card-title
             class="justify-center text-body-1"
             style="white-space: wrap; word-break: keep-all"
             v-bind:class="{ 'text-body-1': bookData.title.length > 20 }"
           >
             {{ bookTitle }}
           </v-card-title>
           <v-card-actions>
             <v-btn text class="ml-2 mt-3" small>Consult</v-btn>
           </v-card-actions>
         </div>
         
         <book-cover
           :cover-image="bookData.coverImage"
           :is-active="bookData.isActive"
         ></book-cover>
       </div>
     </v-card>
   </v-hover>
 </template>-->

<template>
  <v-hover v-slot="{ hover }">
    <v-card
      :elevation="hover && !isActive ? 4 : 2"
      :color="isActive ? 'grey' : ''"
      @click.prevent="$emit('click')"
      :loading="isActive"
      raised
    >
      <v-card-text>
        <book-cover
          :size="'100%'"
          :cover-image="bookData.coverImage"
        ></book-cover>
      </v-card-text>
      <v-card-title
        class="justify-center text-body-1"
        style="white-space: wrap; word-break: keep-all"
        v-bind:class="{ 'text-body-1': bookData.title.length > 20 }"
        >{{ bookTitle }}</v-card-title
      >
      <template v-slot:progress>
        <v-sheet style="width: 100%; height: 0.3rem" color="error"></v-sheet>
      </template>
    </v-card>
  </v-hover>
</template>
<script>
import BookCover from "../Book/BookCover";
import * as Routes from "../../router/route-names";
export default {
  props: {
    book: { type: Object, required: true },
    navigate: { type: Boolean, required: false, default: true },
    displayState: { type: Boolean, required: false, default: true },
  },
  components: { BookCover },
  data() {
    return {};
  },

  computed: {
    bookData() {
      return this.book || { title: "" };
    },
    bookTitle() {
      var wordBreak = 40;
      var bookTitle = this.bookData.title;
      if (bookTitle.length > wordBreak)
        return bookTitle.substring(0, wordBreak) + "...";
      else return bookTitle;
    },
    coverImage() {
      if (this.bookData.coverImage == null) {
        return "/img/book-not-found.jpg";
      } else {
        return this.bookData.coverImage;
      }
    },
    isActive() {
      if (this.displayState) {
        if (this.bookData.isActive) {
          return false;
        } else return true;
      }
      return false;
    },
  },
  watch: {},
  methods: {
    openDetails() {
      if (this.navigate) {
        var id = this.book.publicId;
        this.$router.push({ name: Routes.BOOK, params: { bookId: id } });
      }
    },
  },
};
</script>

<style scoped>
.truncated-title {
  width: 235px;
  max-width: 235px;
}
/* @media screen and (min-width: 1264px) {
  .truncated-title {
    width: 300px;
    max-width: 300px;
  }
} */

@media screen and (min-width: 1900px) {
  .truncated-title {
    width: 400px;
    max-width: 400px;
  }
}
</style>