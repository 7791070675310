<template>
  <v-sheet elevation="0">
    <v-row>
      <!-- <v-spacer></v-spacer>
      <v-col cols="2">
        <v-btn-toggle v-model="layout" dense mandatory rounded>
          <v-btn>
            <v-icon>mdi-view-grid-outline</v-icon>
          </v-btn>
          <v-btn>
            <v-icon>mdi-table-of-contents</v-icon>
          </v-btn>
        </v-btn-toggle>
      </v-col> -->
    </v-row>
    <book-card-display v-if="layout == 0" :books="books"></book-card-display>
    <book-grid-display
      v-else
      :books="books"
      @pagination="$emit('pagination', $event)"
    ></book-grid-display>
  </v-sheet>
</template>
<script>
import BookCardDisplay from "./BookCardDisplay.vue";
import BookGridDisplay from "./BookGridDisplay.vue";
export default {
  props: {
    books: {
      type: Array,
      required: true,
    },
  },
  components: { BookCardDisplay, BookGridDisplay },
  data() {
    return {
      config: {
        isLoaded: false,
        /**
         * 0 for card
         * 1 for grid
         */
        layoutStyle: 1,
      },
    };
  },
  computed: {
    layout: {
      get() {
        return this.config.layoutStyle;
      },
      set(val) {
        this.config.layoutStyle = val;
        var storage = { ...this.config };
        delete storage["isLoaded"];
        localStorage.setItem("libraryDisplayConfig", JSON.stringify(storage));
      },
    },
  },
  watch: {},
  created() {
    if (!this.config.isLoaded) {
      this.config = {
        ...JSON.parse(localStorage.getItem("libraryDisplayConfig")),
      };
      this.config.isLoaded = true;
    }
  },
};
</script>