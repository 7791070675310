import store from '../store'
import roles from '../utils/roles'

export default {
    computed: {
        //#region Role
        isUserSudo() {
            return this.isInRole(roles.Sudo)
        },
        isUserModerator() {
            return this.isInRole(roles.Moderator)
        },
        isUserMember() {
            return this.isInRole(roles.Member)
        },
        //#endregion
        //#region User related actions
        userCanDeactivateUser() {
            return this.isInRole(roles.Sudo)
        },
        userCanChangeUserRole() {
            return this.isInRole(roles.Sudo)
        },
        //#endregion

        //#region Book related actions
        userCanUnlistBook() {
            return this.isInRole(roles.Moderator, roles.Sudo)
        }
        //#endregion
    },
    methods: {
        /**
         * Determine the role of a user
         * @type {Array<string>|string} roles
         * 
         * @returns {boolean} is the user has of these roles. Only one needed
         */
        isInRole(roles) {
            var role = store.getters['auth/role']
            if (typeof roles === 'string') {
                return roles.toLowerCase().trim() === role.toLowerCase().trim()
            }
            else if (Array.isArray(roles)) {
                return roles.some(x => x.toLowerCase().trim() === role.trim().toLowerCase())
            }
            return false;
        }
    },
}