<template>
  <v-menu
    v-bind:value="value"
    v-on:input="$emit('input', $event)"
    :position-x="x"
    :position-y="y"
    absolute
    offset-y
    transition="fade-transition"
  >
    <v-hover v-on:input="setHoverState($event)">
      <v-list>
        <v-list-item
          v-for="(item, index) in contextMenuItems"
          :key="index"
          @mouseenter="openItem($event, item)"
          :disabled="item.disabled"
        >
          <v-list-item-title
            v-if="item.type == 'action'"
            class="pointer"
            @click="doAction($event, item)"
            >{{ item.title }}</v-list-item-title
          >
          <v-list-item-title class="pointer" v-else-if="item.type == 'submenu'">
            {{ item.title }} <v-icon>mdi-chevron-right </v-icon>

            <context-menu
              v-bind:value="item.opened"
              :x="item.x"
              :y="item.y"
              :items="item.children"
              @input="$emit('input', $event)"
            ></context-menu>
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-hover>
  </v-menu>
</template>
<script>
export default {
  name: "context-menu",
  props: {
    x: Number,
    y: Number,
    value: {
      type: Boolean,
      required: false,
      default: false,
    },
    items: {
      required: false,
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      contextMenuItems: [],
      isHover: false,
    };
  },
  watch: {
    items: {
      immediate: true,
      deep: true,
      handler(val) {
        this.createMenuItems(val);
      },
    },
  },
  methods: {
    log(item) {
      console.log(item);
    },
    createMenuItems(val) {
      var filteredItems = val.map((x) => {
        var item = { ...x };

        if (Array.isArray(x["children"]) && x["children"].length > 0) {
          item.type = "submenu";
          item.opened = false;
        } else if (typeof item["action"] == "function") {
          item.type = "action";
        } else if (typeof item["to"] == "object") {
          item.type = "action";
          item.action = () => this.$router.push(item["to"]);
        } else if (typeof item["to"] == "string") {
          item.type = "action";
          item.action = () => this.$router.push({ path: item.to });
        }

        return item;
      });
      filteredItems = filteredItems.filter((x) => x["type"] != null);

      this.contextMenuItems = filteredItems;
    },
    openItem(e, item) {
      e.preventDefault();
      if (item.type == "submenu" && !item.opened) {
        item.opened = false;
        item.x = e.clientX + (e.target.offsetWidth - e.offsetX);
        item.y = e.clientY;
        this.$nextTick(() => {
          item.opened = true;
        });
      } else {
        var closeMenu = (items) => {
          items
            .filter((x) => x.type == "submenu")
            .forEach((x) => {
              closeMenu(x.children);
              x.opened = false;
            });
        };
        closeMenu(this.contextMenuItems);
      }
    },
    // closeItem(e, item) {
    //   if (item.type == "submenu" && item.opened) {
    //     e.preventDefault();
    //     this.$nextTick(() => {
    //       item.opened = false;
    //     });
    //   }
    // },
    doAction(e, item) {
      if (item.type == "action" && !item.disabled) {
        item.action();
        this.$emit("click", e);
        this.$emit("input", false);
      }
    },
    setHoverState(e) {
      if (e) this.$emit("mouseenter", true);
      else this.$emit("mouseleave", false);
      this.$emit("hover", e);
      this.isHover = e;
    },
  },
};
</script>

<style scoped>
.pointer {
  cursor: pointer;
}
</style>