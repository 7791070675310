<template>
  <v-sheet>
    <v-card elevation="0" v-if="user != null">
      <v-card-title>{{ username }}</v-card-title>
      <v-card-text>
        <library-display :books="contributedBooks"></library-display>
      </v-card-text>
    </v-card>
    <v-card elevation="0" v-else>
      <v-card-title>{{ $t("page.userProfile.userNotFound") }}</v-card-title>
    </v-card>
  </v-sheet>
</template>
<script>
import LibraryDisplay from "../../components/Library/LibraryDisplay.vue";
export default {
  props: ["userId"],
  components: { LibraryDisplay },
  data() {
    return {};
  },
  computed: {
    user() {
      return this.$store.getters["user/current"];
    },
    contributedBooks() {
      var books = this.user.contributedBooks;
      if (books == null) return [];
      return books;
    },
    username() {
      var user = this.user;
      if (user.username != null && user.username.length > 0)
        return user.username;
      return user.email;
    },
  },
  watch: {},
  created() {
    if (this.userId != null)
      this.$store.dispatch("user/fetchUser", this.userId);
    else alert("No user id");
  },
};
</script>