<template>
  <v-row v-if="hasBooks">
    <v-col lg="6" md="6" sm="12" v-for="book in books" :key="book.publicId">
      <book-card :book="book"></book-card>
    </v-col>
  </v-row>
  <v-row v-else
    ><v-col
      ><h2>{{ $t("component.libraryDisplay.noBooks") }}</h2></v-col
    ></v-row
  >
</template>
<script>
import BookCard from "./BookCard.vue";
export default {
  props: {
    books: {
      type: Array,
      required: true,
    },
  },
  components: { BookCard },
  data() {
    return {};
  },
  computed: {
    hasBooks() {
      return this.books != null && this.books.length;
    },
  },
  watch: {},
};
</script>