<template>
  <v-avatar class="ma-3 rounded-lg" :size="avatarSize" tile>
    <v-img
      contain
      class="mt-2"
      :src="cover"
      lazy-src="/img/book-not-found.jpg"
      :max-height="maxHeight"
      :gradient="
        !active ? 'to bottom right, rgba(255,255,255,.25), rgba(0,0,0,.25)' : ''
      "
    ></v-img>
  </v-avatar>
</template>
<script>
export default {
  props: ["coverImage", "isActive", "height", "size"],
  data: () => ({
    defaultImage: "/img/book-not-found.jpg",
  }),
  computed: {
    avatarSize() {
      if (this.size != null) {
        return this.size;
      } else return 125;
    },
    cover() {
      return this.coverImage
        ? `data:image/jpeg;base64,${this.coverImage}`
        : this.defaultImage;
    },
    active() {
      if (this.isActive == null) {
        return true;
      }
      return this.isActive;
    },
    maxHeight() {
      if (this.height == null) {
        return "200px";
      }
      return this.height;
    },
  },
};
</script>