<template>
  <v-sheet elevation="0">
    <v-data-table
      :items="books"
      :headers="headers"
      @click:row="navigateTo"
      @contextmenu:row="showContextMenu"
      @pagination="$emit('pagination', $event)"
    >
      <template v-slot:item.isActive="{ item }">
        <!-- <v-btn tile icon @click="deactivateUser(item)"> -->
        <v-icon dense :color="item.isActive ? 'green' : 'red'">{{
          item.isActive ? "mdi-check" : "mdi-cancel"
        }}</v-icon>
        <!-- </v-btn> -->
      </template>
    </v-data-table>
    <context-menu
      v-model="contextMenu.showMenu"
      :items="contextMenuItems"
      :x="contextMenu.x"
      :y="contextMenu.y"
    ></context-menu>
  </v-sheet>
</template>
<script>
import * as Routes from "../../router/route-names";
import roleMixin from "../../mixins/role";
import roles from "../../utils/roles";
import ContextMenu from "../Shared/ContextMenu.vue";
export default {
  props: {
    books: { type: Array, required: true },
    pagination: { type: Object, required: false },
  },
  mixins: [roleMixin],
  components: { ContextMenu },
  data() {
    return {
      contextMenu: {
        showMenu: false,
        x: 0,
        y: 0,
        selectedItem: null,
      },
    };
  },
  computed: {
    headers() {
      var h = [
        { text: this.$t("component.bookGridDisplay.title"), value: "title" },
      ];

      if (this.isInRole(roles.Sudo, roles.Moderator)) {
        h.push({
          text: this.$t("component.bookGridDisplay.headers.isActive"),
          value: "isActive",
        });
      }

      return h;
    },
    contextMenuItems() {
      var menu = [];
      var item = this.contextMenu.selectedItem;
      if (this.isUserSudo && item != null) {
        if (process.env.NODE_ENV != "production")
          menu.push({
            title: "Log",
            action: () => this.log(item),
          });
        menu.push({
          title: item.isActive
            ? this.$t("component.bookGridDisplay.deactivate")
            : this.$t("component.bookGridDisplay.activate"),
          action: () => alert("set book status"),
        });
      }
      return menu;
    },
  },
  watch: {},
  methods: {
    navigateTo($event, row) {
      this.$router.push({
        name: Routes.BOOK,
        params: { bookId: row.item.publicId },
      });
    },
    log(e) {
      console.log(e);
    },
    showContextMenu(e, item) {
      e.preventDefault();
      this.contextMenu.showMenu = false;
      this.contextMenu.x = e.clientX;
      this.contextMenu.y = e.clientY;
      this.contextMenu.selectedItem = item.item;

      this.$nextTick(() => {
        this.contextMenu.showMenu = true;
      });
    },
  },
};
</script>